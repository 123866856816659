import { APP_STORE_BUTTONS, AppStoreButtonColor, useAppStoreLinkFormState } from './hooks'
import { APP_STORE_LINK_IMAGE_URL } from '@dtx-company/inter-app/src/constants/flowpage'
import { AppStoreLinkCard } from '../../flowpage/AppStoreLinkCard'
import { AppStoreLinkIcon } from '@dtx-company/shared-components/src/foundation/Icons/Flowpage/Widgets/AppStoreLink'
import { Box } from '@dtx-company/shared-components/src/components/atoms/Box/index'
import { DESCRIPTION_MAX } from '../validation'
import { DefaultManager, ManagerProps } from '../components/Manager/Manager'
import { DescriptionInput, TitleInput } from '../components/Inputs'
import { DisplayToggleV2 } from '../SocialLink/SocialLinkFormV2/components/DisplayToggleV2'
import { FC } from 'react'
import { FlowpageProps } from '../types/FlowpageProps'
import { FormFields } from '../types'
import { FormProps, StyledForm } from '../components/Layout'
import { FormTitle } from '../components/Title'
import { Icon } from '@dtx-company/shared-components/src/components/atoms/Icon/index'
import { Image } from '@dtx-company/shared-components/src/components/atoms/Image/index'
import { ImageEdit } from '../components/ImageEdit'
import { Input } from '@dtx-company/shared-components/src/components/atoms/Input/index'
import { LinkStyleGated } from '../components/LinkStyle/LinkStyleGated'
import { SettingsAndSaveLink } from '../components/Settings'
import { Spacer } from '@dtx-company/shared-components/src/components/atoms/Spacer/index'
import { StyledCircularProgress } from '../../profile/LinkForm/utils'
import { StyledToggleButton } from '../Destination/EmbedToggle'
import { Text } from '@dtx-company/shared-components/src/components/atoms/Text/index'
import { ToggleButtonList } from '@dtx-company/shared-components/src'
import { appStore, appStoreBadgeDark, playStore, playStoreBadgeDark } from '@app/code/src/assets'
import { getValidatedActionData } from '../typeUtils'
import { v4 as uuid } from 'uuid'

const { TITLE, DESCRIPTION, DISPLAY_TYPE } = FormFields
enum AppStoreUrlFields {
  APPSTOREURL = 'appStoreUrl',
  PLAYSTOREURL = 'playStoreUrl'
}

export const Form: FC<FormProps> = ({ order, curr, handleClose }: FormProps) => {
  const id = curr ? curr.id : uuid()
  const {
    actionData,
    submitting,
    setValue,
    handleSubmit,
    errors,
    onSubmit,
    linkTheme,
    setLinkTheme,
    watchAll,
    disabled,
    edit,
    trigger
  } = useAppStoreLinkFormState(order, curr, handleClose, id)

  return (
    <>
      <StyledForm
        onSubmit={handleSubmit(onSubmit)}
        title={
          <FormTitle
            icon={<AppStoreLinkIcon />}
            title={`${edit ? 'Edit this' : 'Add an'} App Store Link`}
          />
        }
      >
        <Text>At least one URL is required</Text>
        <Spacer mb="17px" />
        <Input
          type="text"
          value={actionData?.appStoreUrl || watchAll.appStoreUrl || ''}
          error={!!errors.appStoreUrl}
          helperText={errors.appStoreUrl?.message}
          onBlur={() => {
            trigger(AppStoreUrlFields.APPSTOREURL)
          }}
          label={'iOS App Store URL'}
          labelProps={{ marginBottom: '6px' }}
          placeholder={'https://apps.apple.com/'}
          onChange={e => {
            setValue(AppStoreUrlFields.APPSTOREURL, e.target.value, { shouldValidate: true })
          }}
          maxWidth="100%"
          startAdornment={
            <Box pl="12px" pr="12px">
              <Icon color="black" src={appStore} alt="app store icon" width="20px" height="20px" />
            </Box>
          }
        />
        <Input
          type="text"
          value={watchAll.playStoreUrl || ''}
          error={!!errors.playStoreUrl}
          helperText={errors.playStoreUrl?.message}
          onBlur={() => trigger(AppStoreUrlFields.PLAYSTOREURL)}
          label={'Google Play Store URL'}
          labelProps={{ marginBottom: '6px' }}
          placeholder={'https://play.google.com/store/'}
          onChange={e => {
            setValue(AppStoreUrlFields.PLAYSTOREURL, e.target.value, { shouldValidate: true })
          }}
          maxWidth="100%"
          startAdornment={
            <Box pl="12px" pr="12px">
              <Icon
                color="black"
                src={playStore}
                alt="play store icon"
                width="20px"
                height="20px"
              />
            </Box>
          }
        />
        {watchAll.displayType !== APP_STORE_BUTTONS ? (
          <>
            <TitleInput
              value={watchAll.title}
              errors={errors}
              placeholder="App Name"
              onChange={e => setValue(TITLE, e.target.value)}
            />
            <DescriptionInput
              value={watchAll.description || ''}
              errors={errors}
              label="Description (optional)"
              placeholder="Download this app!"
              onChange={e => setValue(DESCRIPTION, e.target.value)}
              charMax={DESCRIPTION_MAX}
            />
          </>
        ) : (
          ''
        )}
        <Text>Image Display Type</Text>
        <ToggleButtonList
          selectedValue={watchAll.displayType}
          onChange={(_e, type) => {
            setValue(DISPLAY_TYPE, type)
          }}
          justifyContent="left"
          background="transparent !important"
        >
          <StyledToggleButton
            value="noImage"
            border="1px solid #dadcdd"
            flexDirection="column"
            justifyContent="space-around"
            height="100px"
          >
            <Image src="/static/icons/LinkOption-NoImage.svg" />
            <Text variant="body/small">No image</Text>
          </StyledToggleButton>
          <StyledToggleButton
            value="default"
            border="1px solid  #dadcdd"
            flexDirection="column"
            justifyContent="space-around"
            mr="16px"
            ml="16px"
          >
            <Image src="/static/icons/LinkOption-Thumbnail.svg" />
            <Text variant="body/small">Icon</Text>
          </StyledToggleButton>
          <StyledToggleButton
            value={APP_STORE_BUTTONS}
            border="1px solid #dadcdd"
            flexDirection="column"
            justifyContent="space-around"
            height="100px"
          >
            <Image src={appStoreBadgeDark} alt="app store badge preview" maxWidth="67px" />
            <Image src={playStoreBadgeDark} alt="play store badge preview" maxWidth="67px" />
            <Text variant="body/small">Badge</Text>
          </StyledToggleButton>
        </ToggleButtonList>
        {watchAll.displayType === APP_STORE_BUTTONS && (
          <Box alignItems="center" justifyItems="space-between">
            <Box width="97px">
              <Text fontSize="14px" color="primary.grey">
                Badge Style
              </Text>
            </Box>
            <DisplayToggleV2
              options={[
                { title: 'Dark', value: AppStoreButtonColor.DARK },
                { title: 'Light', value: AppStoreButtonColor.LIGHT }
              ]}
              selectedValue={watchAll.buttonColor}
              onChange={(_e, type) => {
                setValue(FormFields.BUTTON_COLOR, type)
              }}
            />
          </Box>
        )}
        {watchAll.displayType === 'default' && (
          <ImageEdit
            replaceButtonCTA="Replace Icon"
            deleteButtonCTA="Delete Icon"
            imageUrl={watchAll.image || APP_STORE_LINK_IMAGE_URL}
            setValue={setValue}
            defaultImage={APP_STORE_LINK_IMAGE_URL}
            id={id}
            active={curr ? curr.active : true}
          />
        )}
        {!watchAll.appStoreUrl && !watchAll.playStoreUrl && (
          <Text variant="body/small" color="status.errorDark">
            {'At least one URL is required'}
          </Text>
        )}
        <Spacer mt="24px" />
        {watchAll.displayType !== APP_STORE_BUTTONS && (
          <>
            <Spacer mb="16px" />
            <LinkStyleGated linkTheme={linkTheme} setLinkTheme={setLinkTheme} />
          </>
        )}
        <SettingsAndSaveLink curr={curr} handleClose={handleClose} disabled={disabled} />

        {submitting && <StyledCircularProgress />}
      </StyledForm>
    </>
  )
}

export const Flowpage: FC<FlowpageProps> = ({
  link,
  preview = false,

  editLinkMode = false
}: FlowpageProps) => {
  const ad = getValidatedActionData<'destination'>(link.actionData, 'appStoreUrl')
  const onlyTitle = link.displayType !== 'default'
  return (
    <AppStoreLinkCard
      link={{
        ...link,
        actionData: ad
      }}
      widgetId="appStoreLink"
      preview={preview}
      onlyTitle={onlyTitle}
      editLinkMode={editLinkMode}
      iconSrc={link.thumbNailImgUrl || APP_STORE_LINK_IMAGE_URL}
    />
  )
}

export const Manager: FC<ManagerProps> = ({ link, editWidget, key, handle }: ManagerProps) => {
  return (
    <DefaultManager
      altTitle={'App Store Link'}
      handle={handle}
      editWidget={editWidget}
      link={link}
      iconUrl={APP_STORE_LINK_IMAGE_URL}
    />
  )
}
