import {
  APP_STORE_BUTTONS,
  AppStoreButtonColor
} from '../../../components/widgets/AppStoreLink/hooks'
import { Box } from '@dtx-company/shared-components/src/components/atoms/Box/index'
import { CardWrapper, LinkCard, LinkCardBaseProps, StyledLink } from '../FlowpageLinkCard'
import { DisplayAppBadges } from './types'
import { FC, useMemo } from 'react'
import { Image } from '@dtx-company/shared-components/src/components/atoms/Image/index'
import { Spacer } from '@dtx-company/shared-components/src/components/atoms/Spacer/index'
import {
  appStoreBadgeDark,
  appStoreBadgeLight,
  playStoreBadgeDark,
  playStoreBadgeLight
} from '@app/code/src/assets'
import { getLinkCardStyles } from '../FlowpageLinkCard/utils/getLinkCardStyles'
import { useAppStoreLinkCard } from './hooks'
import { useFlowpageAnalyticsCollector } from '../../../hooks/useFlowpageAnalyticsCollector'
import { useFlowpageTheme } from '../../profile/Customize/hooks'
import { useLinkCardManager } from '../FlowpageLinkCard/hooks'

export const AppStoreLinkCard: FC<LinkCardBaseProps> = ({
  link,
  preview,
  iconSrc = '',
  onlyTitle = false,
  newTab = true,
  color,
  bg,
  hidden,
  editLinkMode = false,
  widgetId = null
}: LinkCardBaseProps) => {
  const { cardHeight, iconProps, isFeatured } = useLinkCardManager(link, preview ?? false)
  const { trackLinkClick } = useFlowpageAnalyticsCollector({
    isPreview: Boolean(preview),
    isEditMode: editLinkMode
  })
  const { theme } = useFlowpageTheme()

  const linkStyles = useMemo(
    () =>
      getLinkCardStyles({
        theme,
        linkTheme: link.linkTheme
      }),
    [theme, link.linkTheme]
  )
  const showIcon = hidden || editLinkMode || !link.active
  const { appStoreBadges, processedIconSrc, parentLinkUrl } = useAppStoreLinkCard(link, iconSrc)

  if (link.displayType !== APP_STORE_BUTTONS) {
    return (
      <StyledLink
        id={`hp-flowpage-link_card-${link.id}`}
        href="#"
        rel="noopener"
        onClick={e => {
          e.preventDefault()
          parentLinkUrl && trackLinkClick({ link_id: link.id }, { useHeap: true })
          window.open(parentLinkUrl, '_blank')
        }}
        data-link_id={link.id}
        data-testid="flowpage-link"
        {...(widgetId && { 'data-widget': widgetId })}
      >
        <CardWrapper
          id={link.id}
          {...linkStyles}
          {...(color && { color })}
          {...(bg && { background: bg })}
          minHeight={cardHeight}
          cursor="pointer"
          padding={isFeatured ? '0' : '8px'}
          display="flex"
          flexDirection="column"
          width="auto"
        >
          <LinkCard
            iconSrc={processedIconSrc}
            link={link}
            iconProps={iconProps}
            onlyTitle={onlyTitle}
            showIcon={showIcon}
            hidden={hidden}
            editLinkMode={editLinkMode}
            isFeatured={isFeatured}
            titleFontSize={linkStyles?.fontSize}
          />
        </CardWrapper>
      </StyledLink>
    )
  } else {
    return (
      <Box alignItems="center" justifyContent="center" width="100%">
        {(appStoreBadges === DisplayAppBadges.BOTH || appStoreBadges === DisplayAppBadges.IOS) && (
          <a
            id={`hp-flowpage-link_card-${link.id}-app-store`}
            {...(newTab && { target: '_blank' })}
            href={link?.actionData?.appStoreUrl}
            data-testid="flowpage-badge-app-store"
            onClick={() => !(preview || editLinkMode) && trackLinkClick({ link_id: link.id })}
          >
            <Image
              src={
                link.actionData.buttonColor === AppStoreButtonColor.LIGHT
                  ? appStoreBadgeLight
                  : appStoreBadgeDark
              }
              cursor="pointer"
              height="48px"
            />
          </a>
        )}
        {appStoreBadges === DisplayAppBadges.BOTH && <Spacer mr="8px" />}
        {(appStoreBadges === DisplayAppBadges.BOTH ||
          appStoreBadges === DisplayAppBadges.ANDROID) && (
          <a
            id={`hp-flowpage-link_card-${link.id}-play-store`}
            {...(newTab && { target: '_blank' })}
            href={link?.actionData?.playStoreUrl}
            data-testid="flowpage-badge-play-store"
            onClick={() => !(preview || editLinkMode) && trackLinkClick({ link_id: link.id })}
          >
            <Image
              src={
                link.actionData.buttonColor === AppStoreButtonColor.LIGHT
                  ? playStoreBadgeLight
                  : playStoreBadgeDark
              }
              cursor="pointer"
              height="48px"
            />
          </a>
        )}
      </Box>
    )
  }
}
